.centerCol {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wrapper h1 {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 3.5rem;
    margin-left: 2rem;
  }
}
