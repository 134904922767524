@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* Load custom fonts */

@font-face {
  font-family: 'Londrina Solid';
  src: url(/static/media/LondrinaSolid-Black.ec88da3c.ttf);
  src: url(/static/media/LondrinaSolid-Regular.0d5e1206.ttf);
}
@font-face {
  font-family: 'PT Root UI';
  src: url(/static/media/PT-Root-UI_Regular.51b7ac2a.woff2) format('woff2'),
    url(/static/media/PT-Root-UI_Regular.c8b6805d.woff) format('woff');
}
@font-face {
  font-family: 'PT Root UI';
  font-weight: 500;
  src: url(/static/media/PT-Root-UI_Medium.942457a3.woff2) format('woff2'),
    url(/static/media/PT-Root-UI_Medium.dab54de6.woff) format('woff');
}

@font-face {
  font-family: 'PT Root UI';
  font-weight: bold;
  src: url(/static/media/PT-Root-UI_Bold.b951c0ef.woff2) format('woff2'),
    url(/static/media/PT-Root-UI_Bold.3028ce78.woff) format('woff');
}

:root {
  --brand-bg-green: #edf2f0;
  --brand-dark-red: #d63c5e;
  --brand-light-green: #6da886;
  --brand-black: #212529;
  /* Colors from Figma  */
  --brand-cool-background: #e2d6e2;
  --brand-cool-border: rgb(189, 192, 207);
  --brand-cool-dark-text: #151c3b;
  --brand-cool-light-text: #79809c;
  --brand-cool-accent: #e9ebf3;
  --brand-warm-background: #ebdfb1;
  --brand-warm-border: rgb(207, 189, 186);
  --brand-warm-dark-text: #221b1a;
  --brand-warm-light-text: #8f7e7c;
  --brand-warm-accent: #f9f1f1;
  --brand-gray-dark-text: #14161b;
  --brand-gray-border: #e2e3eb;
  --brand-gray-background: #f4f4f8;
  --brand-gray-light-text: #8c8d92;
  --brand-gray-light-text-translucent: rgb(140, 141, 146, 0.1);
  --brand-gray-hover: #fafafb;
  --brand-color-red: #e40536;
  --brand-color-blue: #4965f0;
  --brand-color-green: #43b369;
  --brand-color-red-translucent: rgba(214, 60, 94, 0.1);
  --brand-color-blue-translucent: rgba(73, 101, 240, 0.1);
  --brand-color-green-translucent: rgba(67, 179, 105, 0.1);
}

.App_wrapper__2Rh_f {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}

body {
  overflow-x: hidden;
}

.Modal_backdrop__3FAPo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.Modal_modal__P3_V5 {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 30vh;
  left: 10%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 17.5rem);
  width: 35rem;
}

.Modal_modal__P3_V5 button {
  border-radius: 10px;
  min-height: 44px;
  padding-top: 7px;
  background-color: rgba(255, 255, 255, 0.7);
}

.Modal_modal__P3_V5 button:hover {
  color: black;
  background-color: rgba(255, 255, 255, 1);
}

.Modal_modal__P3_V5 h3 {
  font-size: xx-large;
  font-weight: bold;
}

.Modal_content__1VIbZ {
  padding: 1rem;
  max-height: 50vh;
  overflow-y: auto;
}

.Modal_modal__P3_V5 .Modal_closeButton__840KP {
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: 0 none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding-top: 2px;
}

.Modal_modal__P3_V5 .Modal_closeButton__840KP img {
  height: 1rem;
  width: 1rem;
  opacity: 0.5;
  transition: all 0.125s ease-in-out;
  margin-top: -4px;
}

.Modal_modal__P3_V5 .Modal_closeButton__840KP img:hover {
  opacity: 1;
}

.Modal_modal__P3_V5 button img {
  margin-top: -2px;
  width: 1.5rem;
  height: auto;
}

@media (max-width: 992px) {
  .Modal_modal__P3_V5 {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.NavBar_navBarBrand__1hw3w {
  position: relative;
  z-index: 2;
  padding: 1rem 0rem;
  transition: all 0.15s ease-in-out;
}

.NavBar_navBarBrand__1hw3w:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.NavBar_navBarLogo__XETFz {
  width: 75px;
  height: 75px;
}

.NavBar_navBarCustom__3zRE7 {
  padding-bottom: 1rem;
}

@media (max-width: 992px) {
  .NavBar_navBarLogo__XETFz {
    width: 65px;
    height: 65px;
  }

  .NavBar_navBarCustom__3zRE7 {
    margin-bottom: -1px;
    margin-right: -5px;
    padding-bottom: 0rem;
  }
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.NavBar_connectBtn__1uOYH {
  cursor: pointer;
}

.NavBar_connectBtn__1uOYH:hover,
.NavBar_connectBtn__1uOYH:active,
.NavBar_connectBtn__1uOYH:focus {
  cursor: pointer !important;
}

.NavBar_disconnectBtn__1B1Vs {
  cursor: pointer;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.NavBar_disconnectBtn__1B1Vs:hover,
.NavBar_disconnectBtn__1B1Vs:active,
.NavBar_disconnectBtn__1B1Vs:focus {
  cursor: pointer !important;
}

.NavBar_greenStatusCircle__1zBA7 {
  display: relative;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: -30px;
  margin-left: 20px;
  margin-top: -20px;
  z-index: 3;
}

.NavBar_nounsNavLink__1_qpP,
.NavBar_nounsNavLink__1_qpP:active,
.NavBar_nounsNavLink__1_qpP:visited {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.3rem 0.3rem !important;
  color: black !important;
}

.NavBar_testnetImg__322vL {
  width: auto;
  height: 45px;
}

.NavBar_addressNavLink__2TScO {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NavBar_brandAndTreasuryWrapper__VzR5S {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.NavBar_navBarToggle__1JXBT {
  border-radius: 10px;
  height: 44px;
  padding: 0.25rem 0.5rem;
  margin-right: 12px;
}

.NavBarButton_wrapper__3Q7Yp {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 12px 0px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.NavBarButton_button__1lFYe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.NavBarButton_icon__2DPCd {
  margin-right: 0.4rem;
}

.NavBarButton_icon__2DPCd > svg {
  max-height: 14px;
  opacity: 0.5;
}

@media (max-width: 992px) {
  .NavBarButton_icon__2DPCd > svg {
    max-height: 17px;
  }
}

.NavBarButton_btnEnabled__3wJVe {
  cursor: pointer;
}

.NavBarButton_btnDisabled__3pkkp {
  cursor: not-allowed;
}

.NavBarButton_coolInfo__1VN2i {
  border: 1px solid var(--brand-cool-border);
  border-radius: 10px;
  color: var(--brand-cool-dark-text);
}

.NavBarButton_coolInfo__1VN2i:hover {
  background-color: var(--brand-cool-accent);
  color: black;
}

.NavBarButton_coolWallet__WExa4 {
  background-color: var(--brand-cool-accent);
  border: 1px solid var(--brand-cool-border);
  border-radius: 10px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
}

.NavBarButton_coolWallet__WExa4:hover {
  background-color: var(--brand-color-blue);
  color: white;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.NavBarButton_warmInfo__8leyD {
  border: 1px solid var(--brand-warm-border);
  border-radius: 10px;
  color: var(--brand-warm-dark-text);
}

.NavBarButton_warmInfo__8leyD:hover {
  background-color: var(--brand-warm-accent);
  color: black;
}

.NavBarButton_warmWallet__7g2Ta {
  background-color: var(--brand-warm-accent);
  border: 1px solid var(--brand-warm-border);
  border-radius: 10px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
}

.NavBarButton_warmWallet__7g2Ta:hover {
  background-color: var(--brand-color-red);
  color: white;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.NavBarButton_whiteInfo__1Zu8n,
.NavBarButton_whiteWallet__2ykTg {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
}

.NavBarButton_whiteInfo__1Zu8n:hover,
.NavBarButton_whiteWallet__2ykTg:hover {
  background-color: #e2e3e8;
  color: black;
}

.NavBarButton_whiteActive__3FVcf {
  background-color: #f4f4f8;
  color: black;
}

.NavBarButton_whiteActive__3FVcf .NavBarButton_icon__2DPCd {
  color: #d63c5e;
}

.NavBarButton_whiteActiveVoteSubmit__47nTT {
  background-color: #e2e3e8;
  color: black;
}

@media (max-width: 992px) {
  .NavBarButton_wrapper__3Q7Yp,
  .NavBarButton_button__1lFYe {
    height: 48px;
    font-size: 18px;
  }
}

.NavBarButton_delegateBack__PfKU8 {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--brand-cool-dark-text);
  width: 100px;
}

.NavBarButton_delegateBack__PfKU8:hover {
  background-color: #e2e3e8;
  color: var(--brand-cool-dark-text);
}

.NavBarButton_delegatePrimary__X54kM {
  background-color: var(--brand-cool-dark-text);
  color: white;
  width: 315px;
}

.NavBarButton_delegatePrimary__X54kM:hover {
  -webkit-filter: brightness(200%);
          filter: brightness(200%);
}

.NavBarButton_delegateSecondary__1RBXu {
  background-color: var(--brand-color-blue);
  color: white;
  width: 315px;
  border: none;
}

.NavBarButton_delegateSecondary__1RBXu:hover {
  -webkit-filter: brightness(200%);
          filter: brightness(200%);
}

.NavBarButton_delegateDisabled__oFcrN {
  background-color: var(--brand-color-blue);
  opacity: 0.5;
  color: white;
  width: 315px;
  border: none;
}

@media (max-width: 992px) {
  .NavBarButton_delegatePrimary__X54kM {
    width: 210px;
  }

  .NavBarButton_delegateSecondary__1RBXu {
    width: 210px;
  }

  .NavBarButton_delegateDisabled__oFcrN {
    width: 210px;
  }
}

.NavBarTreasury_wrapper__1utbs {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 10px 0px 10px;
  transition: all 0.125s ease-in-out;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.NavBarTreasury_button__2dw3R {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.NavBarTreasury_treasuryHeader__1nJhV {
  font-size: 16px;
  margin-left: 0.25rem;
  margin-right: 0.4rem;
  margin-top: 1px;
}

.NavBarTreasury_warmInfo__XAMmy .NavBarTreasury_icon__1x2yt {
  color: #b6a9a7;
  border-left: 1px solid #e1d7d5;
}

.NavBarTreasury_treasuryBalance__3phNO {
  font-size: 16.5px;
  letter-spacing: 0.3px;
  margin-left: 0.4rem;
  margin-right: 0.25rem;
}

@media (max-width: 400px) {
  .NavBarTreasury_wrapper__1utbs {
    margin-left: -0.5rem;
    padding: 0px 7px 0px 6px;
  }

  .NavBarTreasury_treasuryHeader__1nJhV {
    display: none;
  }
}

.NavBarTreasury_warmInfo__XAMmy .NavBarTreasury_treasuryBalance__3phNO {
  color: var(--brand-warm-dark-text);
}

.NavBarTreasury_coolInfo__ABgBg .NavBarTreasury_treasuryBalance__3phNO {
  color: var(--brand-cool-dark-text);
}

.NavBarTreasury_treasuryHeader__1nJhV {
  opacity: 0.5;
}

.NavBarTreasury_button__2dw3R:hover {
  cursor: pointer;
}

.NavBarTreasury_coolInfo__ABgBg {
  border: 1px solid var(--brand-cool-border);
  border-radius: 10px;
  color: var(--brand-cool-dark-text);
}

.NavBarTreasury_coolInfo__ABgBg:hover {
  background-color: var(--brand-cool-accent);
  color: black;
}

.NavBarTreasury_warmInfo__XAMmy {
  border: 1px solid var(--brand-warm-border);
  border-radius: 10px;
  color: var(--brand-warm-light-text);
}

.NavBarTreasury_warmInfo__XAMmy:hover {
  background-color: var(--brand-warm-accent);
  color: var(--brand-warm-dark-text);
  color: black;
}

.NavBarTreasury_whiteInfo__3nH1I {
  background: white;
}

.NavBarTreasury_whiteTreasuryHeader__1EEHp {
  opacity: 0.5;
}

.NavBarTreasury_whiteInfo__3nH1I:hover {
  background-color: #e2e3e8;
}

.NavWallet_icon__2NlNR {
  min-width: 24px;
  margin-right: 0.5rem;
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.NavWallet_connectBtn__1Hs3c {
  cursor: pointer;
}

.NavWallet_connectBtn__1Hs3c:hover,
.NavWallet_connectBtn__1Hs3c:active,
.NavWallet_connectBtn__1Hs3c:focus {
  cursor: pointer !important;
}

.NavWallet_connectContentMobileWrapper__2jXfy {
  margin-left: 0.25rem;
  margin-top: 0.3rem;
}

.NavWallet_connectContentMobileText__2Pvey {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
  margin-top: 1rem;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.NavWallet_disconnectText__2P9P3 {
  color: var(--brand-color-red) !important;
}

.NavWallet_disconnectText__2P9P3:hover {
  color: var(--brand-color-red) !important;
  transition: all 0.125s ease-in-out;
  cursor: pointer;
}

.NavWallet_mobileSwitchWalletText__1h6E9 {
  margin-right: 1rem;
  padding-right: 1rem;
}

.NavWallet_dropDownTop__3nX2O {
  border-radius: 10px 10px 0px 0px;
  padding-top: 0.65rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  justify-content: flex-start;
  margin-left: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.125s ease-in-out;
}

.NavWallet_dropDownTop__3nX2O:hover {
  color: black;
}

.NavWallet_dropDownBottom__TDZAX {
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0.65rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  justify-content: flex-start;
  color: var(--brand-color-red);
  margin-left: 4px;
}

.NavBarDropdown_wrapper__UJDod {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 0px 0px 14px;
  transition: all 0.125s ease-in-out;
  box-shadow: none;
}

.NavBarDropdown_whiteInfo__2hIXV {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
}

.NavBarDropdown_whiteInfo__2hIXV:hover {
  background-color: #e2e3e8;
  color: black;
}

.NavBarDropdown_coolInfo__Mao73 {
  color: #79809c;
  background-color: #e9ebf3;
}

.NavBarDropdown_coolInfo__Mao73:hover {
  background-color: white;
  color: black;
}

.NavBarDropdown_warmInfo__3ARDf {
  color: #b6a9a7;
  background-color: #fdf9f9;
}

.NavBarDropdown_warmInfo__3ARDf:hover {
  background-color: white;
  color: black;
}

.NavBarDropdown_whiteInfoSelected__12CKB {
  background-color: #f4f4f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #8c8d92;
}

.NavBarDropdown_whiteInfoSelectedTop__Jso35 {
  background-color: #f4f4f8;
  border-top: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: #8c8d92;
}

.NavBarDropdown_whiteInfoSelectedBottom__3KxVO {
  background-color: #f4f4f8;
  border-bottom: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: #8c8d92;
}

.NavBarDropdown_dropdownActive__34pY_ {
  background-color: white;
  color: black;
}
.NavBarDropdown_coolInfoSelected__3olh3 {
  color: #79809c;
  background-color: #e9ebf3;
}

.NavBarDropdown_warmInfoSelected__1oOa5 {
  background-color: #fdf9f9;
  color: rgba(142, 129, 127, 1);
}

.NavBarDropdown_button__1LFFf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-right: 14px;
}

.NavBarDropdown_dropdownBtnContent__3pBbO {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 18px;
  line-height: 20px;
  color: black !important;
}

.NavBarDropdown_arrowDown__8N96i {
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
}

.NavBarDropdown_arrowUp__369rR {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.NavBarDropdown_button__1LFFf:hover {
  cursor: pointer;
}

.NavBarDropdown_nounsNavLink__2asPe,
.NavBarDropdown_nounsNavLink__2asPe:active,
.NavBarDropdown_nounsNavLink__2asPe:visited {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.3rem 0.3rem !important;
  color: var(--brand-black) !important;
  transition: all 0.125s ease-in-out;
}

.NavBarDropdown_dropdownPrimaryText__er63w:hover {
  background-color: white;
  transition: all 0.125s ease-in-out;
  cursor: pointer;
}

.NavBarDropdown_desktopDropdown__1NHoC {
  background-color: inherit !important;
  border: 0px !important;
}

@media (max-width: 992px) {
  .NavBarDropdown_wrapper__UJDod,
  .NavBarDropdown_button__1LFFf {
    height: 48px;
    font-size: 18px;
  }
}

@media (max-width: 330px) {
  .NavBarDropdown_button__1LFFf {
    width: 70px;
  }
}

@media (max-width: 370px) {
  .NavBarDropdown_button__1LFFf {
    width: 90px;
  }
}

@media (min-width: 400px) {
  .NavBarDropdown_button__1LFFf {
    width: auto;
  }
}

.WalletButton_walletButton__Q_aK0 {
  border: none;
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: rgba(211, 211, 211, 0.664);
  color: var(--brand-black);
  border: none;
}
.WalletButton_walletButton__Q_aK0 img {
  margin-right: 10px;
  border-radius: 5px;
}
.WalletButton_walletButton__Q_aK0:hover,
.WalletButton_walletButton__Q_aK0:active,
.WalletButton_walletButton__Q_aK0:focus,
.WalletButton_walletButton__Q_aK0:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: lightgray !important;
  color: var(--brand-dark-green);
  color: white;
}

.WalletConnectModal_clickable__2UYOs {
  cursor: pointer;
}

.WalletConnectModal_walletConnectData__1PDIT {
  font-size: 15px;
  padding-top: 1.5rem;
  padding-bottom: 0rem;
  margin-bottom: -1.25rem;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
}

.WalletConnectModal_walletConnectData__1PDIT:hover {
  opacity: 1;
}

.ResponsiveUIUtils_mobileOnly__1tQCt {
  display: none !important;
}

@media (max-width: 1200px) {
  .ResponsiveUIUtils_desktopOnly__2Fw6J {
    display: none;
  }

  .ResponsiveUIUtils_mobileOnly__1tQCt {
    display: inherit !important;
  }
}

@media (max-width: 414px) {
  .ResponsiveUIUtils_disableSmallScreens__1rorn {
    display: none;
  }
}

.NavLocalSwitcher_desktopLanguageButton__UKwTb {
  justify-content: space-between;
}
.NavLocalSwitcher_dropdownActive__2UBrb {
  background-color: white;
  color: black;
}

.NavLocalSwitcher_dropDownTop__2hizO {
  border-radius: 10px 10px 0px 0px;
  padding-top: 0.65rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  margin-left: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.125s ease-in-out;
}

.NavLocalSwitcher_dropDownTop__2hizO:hover {
  color: black;
}

.NavLocalSwitcher_dropDownBottom__JBWAP {
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0.65rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  margin-left: 4px;
}

.NavLocalSwitcher_dropDownBottom__JBWAP:hover {
  color: black;
}

.NavLocalSwitcher_dropDownInterior__22qSG {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 0.65rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  margin-left: 4px;
}

.NavLocalSwitcher_dropDownInterior__22qSG:hover {
  color: black;
}

.NavLocalSwitcher_languageButton__N9WSz {
  border: none;
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: rgba(211, 211, 211, 0.664);
  color: var(--brand-black);
  border: none;
}
.NavLocalSwitcher_languageButton__N9WSz img {
  margin-right: 10px;
  border-radius: 5px;
}
.NavLocalSwitcher_languageButton__N9WSz:hover,
.NavLocalSwitcher_languageButton__N9WSz:active,
.NavLocalSwitcher_languageButton__N9WSz:focus,
.NavLocalSwitcher_languageButton__N9WSz:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: lightgray !important;
  color: var(--brand-dark-green);
  color: white;
}

.LanguageSelectionModal_wrapper__1K4Bd {
  max-height: 40vh;
  overflow-y: scroll;
}

.LanguageSelectionModal_languageButton__1d9DR {
  border: none;
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: rgba(211, 211, 211, 0.664);
  color: var(--brand-black);
  border: none;
  display: flex;
  justify-content: space-between;
}

.LanguageSelectionModal_languageButton__1d9DR:hover,
.LanguageSelectionModal_languageButton__1d9DR:active,
.LanguageSelectionModal_languageButton__1d9DR:focus,
.LanguageSelectionModal_languageButton__1d9DR:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: lightgray !important;
  color: var(--brand-dark-green);
  color: white;
}

.LanguageSelectionModal_icon__l3tdb {
  margin-top: 0.25rem;
}

.Footer_wrapper__1UzXs {
  display: flex;
  flex: 1 1;
}

.Footer_container__st7Zp {
  display: flex;
}

.Footer_footerSignature__3KHrj {
  margin: auto auto 0 auto;
  font-family: 'PT Root UI' !important;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 4rem;
  padding-top: 2rem;
  color: var(--brand-black) !important;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.Footer_footerSignature__3KHrj a {
  text-decoration: none;
  color: black;
  margin: 8px 14px;
  transition: all 0.15s ease-in-out;
}

.Footer_footerSignature__3KHrj a:hover {
  text-decoration: none;
  font-weight: bold;
}

.Footer_footerSignature__3KHrj img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

@media (max-width: 992px) {
  .Footer_footerSignature__3KHrj a {
    font-size: 16px;
  }
}

@-webkit-keyframes Footer_slidein__1VljP {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes Footer_slidein__1VljP {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.Link_link__3euZY,
.Link_link__3euZY:hover,
.Link_link__3euZY:active {
  color: var(--brand-dark-red);
}
.Link_link__3euZY:hover {
  text-decoration: underline;
}

.Banner_centerCol__2Dlch {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Banner_wrapper__1YbFc h1 {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
}

@media (max-width: 992px) {
  .Banner_wrapper__1YbFc {
    padding: 2rem;
  }

  .Banner_wrapper__1YbFc h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 992px) {
  .Banner_wrapper__1YbFc h1 {
    font-size: 3.5rem;
    margin-left: 2rem;
  }
}

.Section_container__1zQTr {
  padding: 2rem 0rem 0rem 0rem;
}

.Noun_img__1GJxo {
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.Noun_imgWrapper__1EdkS {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
}

.Noun_circular__2GVbf {
  border-radius: 50%;
}

.Noun_delegateViewCircular__16he3 {
  border-radius: 50%;
  margin-top: 13px;
}

.Noun_circleWithBorder__3BxRG {
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.Noun_rounded__185en {
  border-radius: 15px;
}

.Noun_circularNounWrapper__-CM3i {
  width: 42px;
  height: 42px;
}

.Noun_delegateViewCircularNounWrapper__2lP2w {
  width: 42px;
  height: 42px;
  margin-left: 12px;
}

@media (max-width: 1200px) {
  .Noun_circularNounWrapper__-CM3i {
    height: 70%;
    width: 70%;
  }
}

.StandaloneNoun_clickableNoun__15g6a {
  cursor: pointer;
}

.AuctionActivity_wrapper__3UpQR {
  padding: 1rem 1rem;
  color: var(--brand-black);
  align-self: flex-start !important;
}

.AuctionActivity_informationRow__2BOSj {
  margin-bottom: 0.5rem;
}

.AuctionActivity_activityRow__1xuKY {
  margin-bottom: 0rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.AuctionActivity_modalHeader__2pkxA {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}

.AuctionActivity_modalTitleWrapper__2w2pt {
  color: var(--brand-black);
}

.AuctionActivity_modalTitleWrapper__2w2pt h1 {
  font-size: xx-large;
  margin-left: 2rem;
}

.AuctionActivity_modalHeader__2pkxA button {
  align-self: flex-start;
}

.AuctionActivity_modalHeaderNounImgWrapper__3boIZ {
  width: 150px;
  height: 150px;
}

.AuctionActivity_currentBidCol__3vgXb {
  border-right: 1px solid #79809c49;
  margin-left: 6px;
  margin-top: 6px;
}

.AuctionActivity_auctionTimerCol__2oKfX {
  padding-left: 0rem;
}

.AuctionActivity_section__hyo3B h4 {
  font-family: 'PT Root UI';
  font-size: 17px;
  line-height: 25px;
}

.AuctionActivity_section__hyo3B h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

@media (max-width: 992px) {
  h1 {
    padding-top: 12px;
  }

  .AuctionActivity_wrapper__3UpQR {
    padding: 1rem;
  }

  .AuctionActivity_auctionTimerCol__2oKfX {
    margin-left: 6px;
    margin-top: 6px;
    padding-left: 0rem;
  }

  .AuctionActivity_currentBidCol__3vgXb {
    border-right: 0px;
    padding-left: 0rem;
  }

  .AuctionActivity_fomoNounsLink__1DC-a {
    padding-left: 14px;
  }
}

@media (max-width: 400px) {
  .AuctionActivity_fomoNounsLink__1DC-a {
    font-size: 14px;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .AuctionActivity_colAlignCenter__3SaC2 {
    display: flex;
    align-items: center;
  }
}

.AuctionActivity_fomoNounsLink__1DC-a {
  margin-top: 0rem;
  margin-bottom: 1rem;
  transition: 0.2s ease-in-out;
}

.AuctionActivity_fomoNounsLink__1DC-a svg {
  opacity: 0.6;
}

.AuctionActivity_fomoNounsLink__1DC-a a,
.AuctionActivity_fomoNounsLink__1DC-a:active a {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  padding-left: 0.5rem;
  text-decoration: none;
  opacity: 1;
  color: rgb(95, 95, 95);
}

.AuctionActivity_fomoNounsLink__1DC-a:hover,
.AuctionActivity_fomoNounsLink__1DC-a:hover a {
  text-decoration: none;
  color: black;
  opacity: 1;
}

.BidHistory_altWrapper__a-MQ4 {
  text-align: center;
}

.BidHistory_bidCollection__2FxcB {
  display: grid;
  text-align: start;
  list-style-type: none;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  padding: 0;
  margin-bottom: 8px;
  margin-top: 1rem;
}

.BidHistory_bidRowCool__1YU1Y {
  font-family: 'PT Root UI', sans-serif;
  padding: 0.8rem;
  border-bottom: 1px solid var(--brand-cool-border);
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.BidHistory_bidRowWarm__1W2Fo {
  font-family: 'PT Root UI', sans-serif;
  padding: 0.25rem 0.75rem 0.5rem 0.75rem;
  border-bottom: 1px solid var(--brand-warm-border);
  font-size: 0.95rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.BidHistory_bidRowWarm__1W2Fo a {
  color: var(--brand-color-red) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.BidHistory_bidRowCool__1YU1Y a {
  color: var(--brand-color-blue) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.BidHistory_bidRowWarm__1W2Fo a:hover,
.BidHistory_bidRowCool__1YU1Y a:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.BidHistory_bidRowCool__1YU1Y:hover,
.BidHistory_bidRowWarm__1W2Fo:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
}

.BidHistory_bidItem__13g5O {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BidHistory_leftSectionWrapper__2T29z {
  display: flex;
  flex-direction: column;
}

.BidHistory_bidder__1hPgQ {
  font-family: 'PT Root UI';
  font-weight: bold;
}

.BidHistory_bidder__1hPgQ > div > div {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.BidHistory_bidRowCool__1YU1Y .BidHistory_bidder__1hPgQ > div > div {
  color: var(--brand-cool-dark-text);
}

.BidHistory_bidRowWarm__1W2Fo .BidHistory_bidder__1hPgQ > div > div {
  color: var(--brand-warm-dark-text);
}

.BidHistory_bidDate__32l9k {
  display: none;
  color: gray;
}

.BidHistory_rightSectionWrapper__3oem5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.BidHistory_bidAmount__7W-Hz {
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-right: 1rem;
  font-size: 18px;
  padding-top: 2px;
}

.BidHistory_bidRowCool__1YU1Y .BidHistory_bidAmount__7W-Hz {
  color: var(--brand-cool-dark-text);
}

.BidHistory_bidRowWarm__1W2Fo .BidHistory_bidAmount__7W-Hz {
  color: var(--brand-warm-dark-text);
}

.BidHistory_linkSymbol__29ypX {
  color: var(--brand-cool-light-text);
}

.BidHistory_linkSymbol__29ypX:hover {
  color: var(--brand-cool-dark-text);
}

@media (max-width: 992px) {
  .BidHistory_bidRow__bc1Zf {
    border-radius: 10px;
  }

  .BidHistory_bidAmount__7W-Hz .BidHistory_linkSymbol__29ypX .BidHistory_bidder__1hPgQ {
    font-size: 16px;
  }
}

.Bid_bidWrapper__1NsAz {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.Bid_bidBtn__2MzFj {
  font-family: 'PT Root UI';
  border-radius: 12px !important;
  margin-left: 0.6rem !important;
  margin-top: 3px;
  width: auto;
  padding: 10px 16px;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
  letter-spacing: normal;
  font-size: 19px;
  transition: all 0.2s ease-in-out;
}

.Bid_bidBtn__2MzFj:disabled {
  cursor: not-allowed;
}

.Bid_bidBtn__2MzFj:hover,
.Bid_bidBtn__2MzFj:active,
.Bid_bidBtn__2MzFj:focus .Bid_bidBtn__2MzFj:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: #2125298a;
}

.Bid_bidBtn__2MzFj:disabled {
  background-color: gray !important;
}

.Bid_minBidCopy__1WI1j {
  padding-top: 0;
  font-size: small;
  margin-left: 0.25rem;
  margin-bottom: 0.3rem;
}

.Bid_bidInput__39un5 {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: auto;
  height: 54px;
  color: black;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: white;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 25px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.Bid_bidInput__39un5:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-cool-dark-text) !important;
}

.Bid_customPlaceholder__3KOvn {
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  z-index: 3;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Bid_customPlaceholderBidAmt__3s0HL {
  position: absolute;
  opacity: 0.3;
  top: 15%;
  left: 3%;
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 25px;
  z-index: 1;
  pointer-events: none;
}

.Bid_activityRow__ltD0z .Bid_form-floating__n80tb {
  display: flex !important;
  flex-flow: row nowrap !important;
  justify-content: space-between !important;
}

.Bid_bidBtnAuctionEnded__1zL5T {
  width: 100%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
  font-family: 'PT Root UI';
  font-size: 18px;
  border-radius: 10px;
}

.Bid_bidBtnAuctionEnded__1zL5T:hover,
.Bid_bidBtnAuctionEnded__1zL5T:active,
.Bid_bidBtnAuctionEnded__1zL5T:focus,
.Bid_bidBtnAuctionEnded__1zL5T:disabled {
  background-color: gray !important;
  color: rgb(209, 207, 207);
  outline: none !important;
  box-shadow: none;
}

.Bid_bidInputAuctionEnded__3sHIA {
  display: none;
}

.Bid_bidInput__39un5:focus {
  outline: none;
}

/* REMOVE UP/DOWN ARROWS FROM INPUT */
/* Chrome, Safari, Edge, Opera */
.Bid_bidInput__39un5::-webkit-outer-spin-button,
.Bid_bidInput__39un5::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.Bid_bidInput__39un5[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 992px) {
  .Bid_bidInput__39un5 {
    border: none;
    border: 1px solid #8c8d9275;
  }

  .Bid_customPlaceholder__3KOvn {
    left: 40%;
  }
  .Bid_customPlaceholderBidAmt__3s0HL {
    left: 3.5%;
    top: 16%;
  }
  .Bid_emergencySettleWrapper__3occd {
    text-align: center;
  }

  .Bid_voteForNextNounBtnWrapper__1PquX {
    width: 100%;
  }
}

.Bid_emergencySettleButton__2qTtx {
  color: var(--brand-dark-red);
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

@media (max-width: 992px) {
  .SettleManuallyBtn_emergencySettleWrapper__1_H1Q {
    text-align: center;
    margin-left: 0.5rem;
  }
}

@media (max-width: 660px) {
  .SettleManuallyBtn_emergencySettleWrapper__1_H1Q {
    text-align: center;
    margin-left: 0rem;
  }
}

.SettleManuallyBtn_emergencySettleButton__2ov3p {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
.SettleManuallyBtn_emergencySettleButton__2ov3p:disabled {
  color: #8c8d92;
  text-decoration: none;
  cursor: default;
}

.AuctionTimer_section__1mqHL span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Londrina Solid';
}

.AuctionTimer_section__1mqHL h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.AuctionTimer_section__1mqHL h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0px !important;
  margin-top: 3px;
}

.AuctionTimer_wrapper__FLota {
  padding-left: 2.5rem;
  padding-right: 0rem;
  cursor: pointer;
  margin-top: 0.3rem;
  width: -webkit-max-content;
  width: max-content;
}

.AuctionTimer_timerWrapper__3c10Z {
  display: flex;
  margin-top: 1px;
}

.AuctionTimer_timerSection__2RlJK span {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.AuctionTimer_timerSection__2RlJK {
  margin-right: 0.5rem;
}

.AuctionTimer_timerSectionFinal__ZRiyN {
  margin-right: 0rem;
}

.AuctionTimer_timerSectionFinal__ZRiyN span {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.AuctionTimer_leftCol__2xmED {
  margin-top: 1px;
}

@media (max-width: 992px) {
  .AuctionTimer_section__1mqHL h4 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 6px;
  }

  .AuctionTimer_section__1mqHL h2 {
    font-size: 23px;
  }

  .AuctionTimer_section__1mqHL {
    justify-content: space-between;
  }

  .AuctionTimer_wrapper__FLota {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }

  .AuctionTimer_leftCol__2xmED {
    margin-top: 0px;
    padding-left: 0.5rem;
  }

  .AuctionTimer_timerSection__2RlJK span {
    font-size: 23px;
  }

  .AuctionTimer_timerSectionFinal__ZRiyN span {
    font-size: 23px;
  }

  .AuctionTimer_title__1TwqG {
    font-size: 18px;
    margin-top: 1.4rem;
  }

  .AuctionTimer_timeLeft__-MeOt {
    padding-right: 0.5rem;
  }
}

.CurrentBid_section__2oRi6 span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Londrina Solid';
}

.CurrentBid_section__2oRi6 h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.CurrentBid_section__2oRi6 h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0px !important;
  margin-top: 3px;
}

.CurrentBid_wrapper__2-jFi {
  padding-left: 0rem;
  padding-right: 0rem;
}

@media (max-width: 992px) {
  .CurrentBid_section__2oRi6 h4 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 6px;
  }

  .CurrentBid_section__2oRi6 h2 {
    font-size: 23px;
  }

  .CurrentBid_section__2oRi6 {
    justify-content: space-between;
  }

  .CurrentBid_currentBid__3k-Hq {
    margin-right: 0.5rem;
  }

  .CurrentBid_wrapper__2-jFi {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .CurrentBid_leftCol__39uuw {
    padding-left: 0.5rem;
  }
}

.Winner_wrapper__3kdqN {
  margin-left: 0.5rem;
  margin-top: 2px;
  padding-left: 1.5rem;
}

.Winner_youCopy__4J8BH {
  margin-top: 0.25rem;
}

.Winner_winnerCopy__QFc-n {
  min-width: 250px;
}

.Winner_link__1Xu57,
.Winner_link__1Xu57:hover,
.Winner_link__1Xu57:active {
  color: black;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.Winner_verifyButton__2tAXA {
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-family: 'PT Root UI';
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
}

.Winner_verifyButton__2tAXA:hover,
.Winner_verifyButton__2tAXA:active,
.Winner_verifyButton__2tAXA:focus,
.Winner_verifyButton__2tAXA:disabled {
  background-color: gray !important;
  outline: none !important;
  box-shadow: none !important;
}

.Winner_section__saqU7 h4 {
  font-family: 'PT Root UI';
  font-size: 18px;
  line-height: 27px;
}

.Winner_section__saqU7 h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.Winner_leftCol__2JIIV {
  font-family: 'PT Root UI';
}

.Winner_leftCol__2JIIV h4 {
  font-weight: bold;
}

@media (max-width: 992px) {
  .Winner_section__saqU7 h4 {
    font-size: 18px;
  }

  .Winner_section__saqU7 h2 {
    font-size: 23px;
  }

  .Winner_section__saqU7 {
    justify-content: space-between;
  }

  .Winner_wrapper__3kdqN {
    margin-top: 0px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .Winner_leftCol__2JIIV {
    padding-left: 0.5rem;
  }

  .Winner_winnerContent__2GSn2 {
    margin-right: 0.5rem;
  }

  .Winner_wrapper__3kdqN {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .Winner_verifyButton__2tAXA {
    width: 100%;
    height: 3rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .Winner_verifyLink__2gbHQ {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .Winner_youCopy__4J8BH {
    padding-right: 0.25rem;
  }
}

.ShortAddress_shortAddress__16h3M {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}

.ShortAddress_shortAddress__16h3M > div {
  padding-top: -2px;
}

.ShortAddress_shortAddress__16h3M span {
  letter-spacing: 0.2px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.Tooltip_hover__3UWp4 {
  border-radius: 8px !important;
}

.AuctionNavigation_navArrowsContainer__3hTLA {
  position: absolute;
}

.AuctionNavigation_leftArrowCool__ZifA- {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 50%;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  font-weight: bold;
}

.AuctionNavigation_rightArrowCool__z7pDj {
  -webkit-appearance: none;
  padding: 0;
  margin-left: 0.3rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 50%;
  font-weight: bold;
}

.AuctionNavigation_leftArrowWarm__1kQgX {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 50%;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  font-weight: bold;
}

.AuctionNavigation_rightArrowWarm__3ZiRq {
  -webkit-appearance: none;
  padding: 0;
  margin-left: 0.3rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  border-radius: 50%;
  font-weight: bold;
}

/* Disable hover on mobile because it was leading to
buttons looking disabled when they were not */
@media (min-width: 992px) {
  .AuctionNavigation_leftArrowWarm__1kQgX:hover,
  .AuctionNavigation_leftArrowCool__ZifA-:hover,
  .AuctionNavigation_rightArrowWarm__3ZiRq:hover,
  .AuctionNavigation_rightArrowCool__z7pDj:hover {
    opacity: 0.9;
    background-color: var(--brand-gray-hover);
  }
}

.AuctionNavigation_leftArrowWarm__1kQgX:disabled,
.AuctionNavigation_leftArrowCool__ZifA-:disabled,
.AuctionNavigation_rightArrowWarm__3ZiRq:disabled,
.AuctionNavigation_rightArrowCool__z7pDj:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@-moz-document url-prefix() {
  .AuctionNavigation_navArrowsContainer__3hTLA {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 992px) {
  .AuctionActivityWrapper_wrapper__1JNe0 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM {
  display: flex;
}

@media (max-width: 992px) {
  .AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM h1 {
    font-size: 2.75rem;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM {
    display: flex;
    align-items: center;
  }
}

.AuctionActivityNounTitle_wrapper__2ia4b {
  display: inline-block;
}

.AuctionActivityNounTitle_wrapper__2ia4b h1 {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-size: 54px;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .AuctionActivityNounTitle_wrapper__2ia4b h1 {
    font-size: 56px;
  }
}

.AuctionActivityDateHeadline_date__3MSCC {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 17px;
  line-height: 27px;
  margin-top: 0.22rem;
}

.AuctionActivityDateHeadline_wrapper__2YbIC {
  margin-left: 5rem;
  width: auto;
}

.BidHistoryBtn_bidHistoryWrapper__3Zsy9 {
  background-color: var(--brand-cool-accent);
  display: flex;
  border-radius: 10px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.BidHistoryBtn_bidHistoryWrapperCool__3tt85 {
  display: flex;
  border-radius: 10px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--brand-cool-light-text);
}

.BidHistoryBtn_bidHistoryWrapperWarm__2AkZO {
  display: flex;
  border-radius: 10px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--brand-warm-light-text);
}

.BidHistoryBtn_bidHistory__2lmSd {
  margin-left: 0.5rem;
  color: var(--brand-color-cool);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 1rem;
  font-size: 16px;
}

.BidHistoryBtn_bidHistoryWarm__2sAuu {
  margin-left: 0.5rem;
  color: var(--brand-color-red);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 1rem;
  font-size: 16px;
}

.BidHistoryBtn_bidHistoryCool__2cAxM {
  margin-left: 0.5rem;
  color: var(--brand-color-blue);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 1rem;
  font-size: 16px;
}

.BidHistoryBtn_bidHistoryWarm__2sAuu,
.BidHistoryBtn_bidHistoryCool__2cAxM {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.BidHistoryBtn_bidHistoryWarm__2sAuu:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  color: var(--brand-color-red);
}

.BidHistoryBtn_bidHistoryCool__2cAxM:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  color: var(--brand-color-blue);
}

.BidHistoryBtn_bidHistoryWrapperWarm__2AkZO:hover {
  color: var(--brand-color-warm);
}

.BidHistoryBtn_bidHistoryWrapperCool__3tt85:hover {
  color: var(--brand-color-blue);
}

.BidHistoryBtn_bidHistoryWrapper__3Zsy9:hover {
  background-color: var(--brand-gray-hover);
}

.NounInfoCard_nounInfoHeader__r6K6B {
  display: inline-block;
  padding-left: 0rem;
  font-family: 'Londrina Solid';
}

@media only screen and (max-width: 600px) {
  .NounInfoCard_nounInfoHeader__r6K6B {
    padding-top: 1.5rem;
    margin-bottom: 0;
  }
}

.NounInfoCard_nounInfoHeader__r6K6B h2 {
  font-size: 56px;
  margin-bottom: 18px;
}

.NounInfoCard_nounInfoHeader__r6K6B h3 {
  font-size: 24px;
  margin-bottom: '0px';
  color: #79809c;
}

.NounInfoCard_nounInfoRow__1iqd0 {
  font-family: 'PT Root UI';
  font-weight: 500;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 18px;
  display: flex;
}

.NounInfoCard_nounInfoRowText__tGZn_ {
  padding-left: 4;
  font-family: 'PT Root UI';
  font-family: bold;
}

.NounInfoRowBirthday_nounInfoRowBirthday__LxZeH {
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-left: 5px;
}

.NounInfoRowBirthday_birthdayInfoContainer__20t05 {
  display: inline;
  width: 350px;
  height: 25px;
}

.NounInfoRowBirthday_birthdayIcon__1Ud68 {
  margin-bottom: 4px;
  margin-right: 7px;
}

.NounInfoRowHolder_nounHolderEtherscanLinkCool__1dIFu {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: var(--brand-color-blue);
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowHolder_nounHolderEtherscanLinkWarm__3G3Ei {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: var(--brand-color-red);
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowHolder_nounHolderEtherscanLinkWarm__3G3Ei:hover {
  color: var(--brand-color-red) !important;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.NounInfoRowHolder_nounHolderEtherscanLinkCool__1dIFu:hover {
  color: var(--brand-color-blue) !important;
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.NounInfoRowHolder_nounHolderInfoContainer__2_HY- {
  display: inline;
  width: 350px;
  height: 35px;
}

.NounInfoRowHolder_nounHolderLoading__1TOqk {
  opacity: 0.5;
}

.NounInfoRowHolder_linkIconSpan__1qf4h {
  margin-left: 5px;
}

.NounInfoRowHolder_linkIcon__2dEEO {
  margin-bottom: 5px;
}

.NounInfoRowHolder_heartIcon__2eIiK {
  margin-bottom: 4px;
  margin-right: 7px;
}

.NounInfoRowButton_nounButtonCool__1Fw_Q {
  height: 40px;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowButton_nounButtonWarm__10sxf {
  height: 40px;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowButton_nounButton__3kRR_ {
  height: 40px;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowButton_nounButtonCool__1Fw_Q:hover,
.NounInfoRowButton_nounButtonWarm__10sxf:hover,
.NounInfoRowButton_nounButton__3kRR_:hover {
  text-decoration: none;
  background-color: var(--brand-gray-hover);
}

.NounInfoRowButton_nounButton__3kRR_:active {
  color: black;
}

.NounInfoRowButton_nounButtonContents__33b3h {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.NounInfoRowButton_buttonIcon__RtQbH {
  margin-right: 5px;
}

.BidHistoryModal_backdrop__1Lb6Z {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(75, 75, 75, 0.5);
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
}

.BidHistoryModal_content__3bPMZ {
  padding: 1rem;
  max-height: 50vh;
  overflow-y: hidden;
}

.BidHistoryModal_modal__1XM9S {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 15vh;
  left: 10%;
  z-index: 100;
  background-color: rgba(244, 244, 248, 1);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  border-radius: 24px;
  left: calc(50% - 20rem);
  width: 40rem;
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_header__1_73Y {
  display: flex;
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_title__1Kyqj {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_title__1Kyqj h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_title__1Kyqj h1 {
  line-height: 25px;
  height: 2rem;
  font-size: 42px;
}

.BidHistoryModal_nounWrapper__j_bT5 {
  height: 96px;
  width: 96px;
  margin-right: 1rem;
}

.BidHistoryModal_bidWrapper__2b-ho {
  background-color: rgba(224, 224, 231, 1);
  overflow-y: scroll;
  box-shadow: inset 0px -12px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin-top: 1rem;

  height: 35vh;
  padding: 0.75rem;
}

.BidHistoryModal_bidWrapper__2b-ho ul {
  list-style: none;
  padding: 0;
}

.BidHistoryModal_closeBtnWrapper__2q7QA {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.BidHistoryModal_closeBtn__1XSZ2 {
  z-index: 100;
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.125s ease-in-out;
  border: 0;
}

.BidHistoryModal_closeBtn__1XSZ2:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.BidHistoryModal_closeBtn__1XSZ2 .BidHistoryModal_icon__ioce8 {
  height: 24px;
  width: 24px;
}

.BidHistoryModal_nullStateText__3Fax- {
  font-size: 24px;
  text-align: center;
  margin-top: 3rem;
}

@media (max-width: 992px) {
  .BidHistoryModal_backdrop__1Lb6Z {
    background: rgba(0, 0, 0, 0.74);
  }

  .BidHistoryModal_content__3bPMZ {
    max-height: 100%;
    height: 100%;
  }

  .BidHistoryModal_modal__1XM9S {
    top: 5vh;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .BidHistoryModal_modal__1XM9S .BidHistoryModal_header__1_73Y h2 {
    color: rgba(140, 141, 146, 1);
  }

  .BidHistoryModal_modal__1XM9S .BidHistoryModal_header__1_73Y h1 {
    color: white;
    line-height: 0px;
  }

  .BidHistoryModal_bidWrapper__2b-ho {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0rem;
    height: 100%;
  }

  .BidHistoryModal_nullStateText__3Fax- {
    color: white;
  }
}

.BidHistoryModalRow_bidRow___Gzh7 {
  border-radius: 14px;
  height: 72px;
  width: 100%;
  padding: 1rem;
  margin-top: 0.75rem;
  border-bottom: 0px;
  background-color: white;
}

.BidHistoryModalRow_bidderInfoWrapper__3X5Vl {
  display: flex;
}

.BidHistoryModalRow_bidderInfoText__3X6ON {
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0;
  line-height: 23px;
}

.BidHistoryModalRow_bidDate__2I3JU {
  color: rgba(140, 141, 146, 1);
  font-weight: 500;
  font-family: 'PT Root UI';
  font-size: 13px;
}

.BidHistoryModalRow_trophy__1XvXd {
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
}

.BidHistoryModalRow_linkIcon__2f8xw {
  color: var(--brand-gray-light-text);
  transition: all 0.125s ease-in-out;
  margin-bottom: 0.1rem;
}

.BidHistoryModalRow_linkIcon__2f8xw:hover {
  color: black;
  cursor: pointer;
}

.BidHistoryModalRow_bidAmount__arRw- {
  white-space: nowrap;
}

.Holder_wrapper__1C3nB {
  margin-left: 0.5rem;
  margin-top: 2px;
  padding-left: 1.5rem;
}

.Holder_youCopy__2W_wo {
  margin-top: 0.25rem;
}

.Holder_holderCopy__21_3- {
  min-width: 250px;
}

.Holder_section__kmw-U h4 {
  font-family: 'PT Root UI';
  font-size: 18px;
  line-height: 27px;
}

.Holder_section__kmw-U h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.Holder_leftCol__261m5 {
  font-family: 'PT Root UI';
}

.Holder_leftCol__261m5 h4 {
  font-weight: bold;
}

.Holder_link__1MvJv,
.Holder_link__1MvJv:hover,
.Holder_link__1MvJv:active {
  color: black;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.Holder_holderContent__2eFV8 {
  white-space: nowrap;
}

@media (max-width: 992px) {
  .Holder_section__kmw-U h4 {
    font-size: 18px;
  }

  .Holder_section__kmw-U h2 {
    font-size: 23px;
  }

  .Holder_section__kmw-U {
    justify-content: space-between;
  }

  .Holder_wrapper__1C3nB {
    margin-top: 0px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .Holder_leftCol__261m5 {
    padding-left: 0.5rem;
  }

  .Holder_holderContent__2eFV8 {
    margin-right: 0.5rem;
  }

  .Holder_wrapper__1C3nB {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.Auction_nounWrapper__3JSNc {
  align-self: flex-end;
  width: 100%;
}

.Auction_nounContentCol__1o5ER {
  display: flex;
}

.Auction_auctionActivityCol__3U2jw {
  padding-right: 5rem;
  padding-bottom: 0rem;
  min-height: 520px;
  align-self: flex-end !important;
}

@media (max-width: 992px) {
  .Auction_nounWrapper__3JSNc {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .Auction_auctionActivityCol__3U2jw {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
    background-color: white;
  }
}

@media (max-width: 568px) {
  .Auction_auctionActivityCol__3U2jw {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-bottom: 2rem;
    padding-top: 2rem;
    padding-right: unset;
    padding-left: 0;
  }

  .Auction_nounWrapper__3JSNc {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2rem;
  }

  .Auction_nounContentCol__1o5ER {
    padding: 0rem;
  }
}

.NounderNounContent_link__3MuKL,
.NounderNounContent_link__3MuKL:visited,
.NounderNounContent_link__3MuKL:active {
  color: var(--brand-dark-green);
  text-decoration: underline;
}

.NounderNounContent_link__3MuKL:hover {
  color: var(--brand-dark-red);
}

.NounderNounContent_bidRow__2CVAB {
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
}

.NounderNounContent_currentBidCol__3VQRX {
  border-right: 0 !important;
}

.Documentation_headerWrapper__xln_n h1 {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-size: 3rem;
}

.Documentation_headerWrapper__xln_n p {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;
  margin: 1.25rem 0;
}

.Documentation_accordionItem__1PEt2 {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;
  font-size: 1.2rem !important;
}

@media (max-width: 992px) {
  .Documentation_headerWrapper__xln_n {
    padding: 1rem;
  }
}

.Documentation_accordionItem__1PEt2 {
  margin-bottom: 2.5rem;
  border: none;
}

.Documentation_aboutText__2Ma74 {
  margin: 0px !important;
}

.Documentation_accordionItem__1PEt2,
.Documentation_headerWrapper__xln_n {
  font-size: 1.3rem;
}

.Documentation_accordionHeader__2Pk8x button:hover {
  color: var(--brand-dark-red) !important;
}

.Documentation_accordionHeader__2Pk8x button {
  border: none;
  background-color: transparent;
  font-family: 'Work Sans';
  font-weight: 900;
  font-size: 2rem !important;
  cursor: pointer;
  line-height: normal;
  padding-top: 0;
  padding-bottom: 0;
}

.Documentation_accordionHeader__2Pk8x button:not(.Documentation_collapsed__3KbMo) {
  color: #212529;
  background-color: transparent;
  box-shadow: none !important;
}

/* override bootstrap color */
.Documentation_accordionHeader__2Pk8x button:not(.Documentation_collapsed__3KbMo)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.Documentation_accordionHeader__2Pk8x button:focus {
  color: var(--brand-dark-red) !important;
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .Documentation_accordionHeader__2Pk8x button {
    padding-left: 20px;
  }
}

.ProfileActivityFeed_headerWrapper__33MV0 h1 {
  font-family: 'Londrina Solid';
  font-size: 32px;
}

.ProfileActivityFeed_headerWrapper__33MV0 p {
  margin: 3rem 0;
}

@media (min-width: 992px) {
  .ProfileActivityFeed_headerWrapper__33MV0 {
    padding: 1rem;
    display: block;
    padding-bottom: 16px;
  }
}

.ProfileActivityFeed_nounInfoPadding__2Om27 {
  padding-bottom: 1rem;
  font-size: 18px;
}

.ProfileActivityFeed_expandCollapseCopy__3l2EY {
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}

.ProfileActivityFeed_aboveTheFoldEventsTable__2fL2g {
  margin-bottom: 0rem;
}

.ProfileActivityFeed_aboveTheFoldEventsTable__2fL2g td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 12px;
}

.ProfileActivityFeed_nullStateCopy__1ObAe {
  opacity: 0.5;
  margin-left: 1rem;
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 18px;
}

.ProfileActivityFeed_spinner__3jpZC {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  color: var(--brand-gray-light-text);
}

.ProfileActivityFeedToggle_expandCollapseCopy__y9obe {
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}

.BrandSpinner_spinner__1s9t7 {
  -webkit-animation: BrandSpinner_rotate__QdcCR 1.25s linear infinite;
          animation: BrandSpinner_rotate__QdcCR 1.25s linear infinite;
  -webkit-transform-origin: center center !important;
          transform-origin: center center !important;
}

@-webkit-keyframes BrandSpinner_rotate__QdcCR {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes BrandSpinner_rotate__QdcCR {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.DesktopDelegationEvent_delegateHover__3Ra5G {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopDelegationEvent_scaleIconWrapper__3L1bb {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.DesktopDelegationEvent_scaleIcon__1t2DG {
  height: 22px;
  width: 22px;
}

.DesktopDelegationEvent_address__1u_yL {
  font-weight: bold;
  cursor: pointer;
}

.DesktopDelegationEvent_externalLinkIcon__3tsMy {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

.DesktopDelegationEvent_transactionHashWrapper__2RiSM {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.DesktopNounActivityRow_wrapper__1J9tT {
  transition: all 0.2s ease-in-out;
}

.DesktopNounActivityRow_pointer__1NDkC {
  cursor: pointer;
}

.DesktopNounActivityRow_wrapper__1J9tT:hover {
  --bs-table-hover-bg: rgba(0, 0, 0, 0.03);
}

.DesktopNounActivityRow_icon__3eTWD {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.DesktopNounActivityRow_activityTableCell__3XHtk {
  max-width: 50vw;
}

.DesktopNounActivityRow_infoContainer__2k2-M {
  display: inline-block;
  margin-top: 4px;
  width: 100%;
}
@media (max-width: 992px) {
  .DesktopNounActivityRow_infoContainer__2k2-M {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.DesktopNounActivityRow_secondaryContentWrapper__3KWUl {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.DesktopNounActivityRow_secondaryContentContainer__1atvn {
  text-align: right;
  width: -webkit-max-content;
  width: max-content;
}

.TransactionHashPill_transactionHashWrapper__2OCwj {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}
.TransactionHashPill_transactionHashWrapper__2OCwj:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.TransactionHashPill_externalLinkIcon__g68m_ {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

@media (max-width: 992px) {
  .TransactionHashPill_externalLinkIcon__g68m_ {
    margin-bottom: 0.135rem;
  }
}

.DesktopNounWinEvent_delegateHover__3OI0v {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopNounWinEvent_switchIconWrapper__8IcRT {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.DesktopNounWinEvent_switchIcon__3dp2b {
  height: 22px;
  width: 22px;
}

.DesktopNounWinEvent_address__107Lk {
  font-weight: bold;
  cursor: pointer;
}

.DesktopNounWinEvent_externalLinkIcon__z8oDr {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

.DesktopNounWinEvent_transactionHashWrapper__3WlI_ {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.DesktopNounWinEvent_bold__ud3km {
  font-weight: bold;
}

.DesktopProposalVoteEvent_voteIcon__2wOOw {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.DesktopProposalVoteEvent_proposalTitle__lgVjZ {
  text-decoration: none;
  color: black;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
}

.DesktopProposalVoteEvent_delegateHover__1psVG {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.VoteStatusPill_nounButton__1VHQj {
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  color: var(--brand-color-green);
  background-color: var(--brand-color-green-translucent);
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.VoteStatusPill_pass__3EIR8 {
  color: var(--brand-color-blue);
  background-color: var(--brand-color-blue-translucent);
}

.VoteStatusPill_fail__2wF4b {
  color: var(--brand-color-red);
  background-color: var(--brand-color-red-translucent);
}

.VoteStatusPill_pending__3d_z7 {
  color: var(--brand-color-green);
  background-color: var(--brand-color-green-translucent);
}

.ProposalVoteInfoPills_wrapper__cn13c {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  .ProposalVoteInfoPills_wrapper__cn13c {
    justify-content: flex-start;
  }
}

.ProposalVoteInfoPills_voteStatusWrapper__3eD-a {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.ProposalVoteHeadline_voterLink__1zFRt {
  font-weight: bold;
  cursor: pointer;
}

.ProposalVoteHeadline_delegateHover__3LKm0 {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopTransferEvent_delegateHover__1bpHn {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopTransferEvent_switchIconWrapper__19id- {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.DesktopTransferEvent_switchIcon__2BBFN {
  height: 22px;
  width: 22px;
}

.DesktopTransferEvent_address__1MPkM {
  font-weight: bold;
  cursor: pointer;
}

.DesktopTransferEvent_externalLinkIcon__7NBuC {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

.DesktopTransferEvent_transactionHashWrapper__1TUl_ {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.DesktopProfileActivityFeed_aboveTheFoldEventsTable__2UIky {
  margin-bottom: 0rem;
}

.DesktopProfileActivityFeed_aboveTheFoldEventsTable__2UIky td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 12px;
}

.DesktopProfileActivityFeed_nounInfoPadding__2Bhvn {
  padding-bottom: 1rem;
  font-size: 18px;
}

.MobileDelegationEvent_scaleIconWrapper__1sALi {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.MobileDelegationEvent_scaleIcon__3bHM7 {
  height: 22px;
  width: 22px;
}

.MobileDelegationEvent_bold__1Y83q {
  font-weight: bold;
}

.MobileNounActivityRow_wrapper__3-L7o {
  border-bottom: 1px solid var(--brand-gray-border);
  padding-bottom: 0.5rem;
  font-family: PT Root UI;
  font-size: 18px;
  margin-top: 1rem;
  display: flex;
}

.MobileNounActivityRow_icon__3n-VO {
  width: 38px;
  margin-right: 0px;
  margin-top: 0.25rem;
  margin-left: 6px;
}

.MobileNounActivityRow_content__cEDSP {
  margin-left: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.MobileNounWinEvent_iconWrapper__17_yv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.MobileNounWinEvent_switchIcon__UKYG_ {
  height: 22px;
  width: 22px;
}

.MobileNounWinEvent_bold__2J-F1 {
  font-weight: bold;
}

.MobileProposalVoteEvent_voteIcon__rlh4E {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.MobileProposalVoteEvent_proposalTitle__3enqs {
  text-decoration: none;
  color: black;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.MobileTransferEvent_switchIconWrapper__SSRX1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.MobileTransferEvent_switchIcon__W1ac2 {
  height: 22px;
  width: 22px;
}

.MobileTransferEvent_bold__3gKys {
  font-weight: bold;
}

.ProposalStatus_proposalStatus__3RcqE {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  border: 0rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
}

.ProposalStatus_primary__32vJB {
  background-color: var(--brand-color-green);
}

.ProposalStatus_success__1OL1A {
  background-color: var(--brand-color-blue);
}

.ProposalStatus_danger__12g7A {
  background-color: var(--brand-color-red);
}

.ProposalStatus_secondary__3jdRV {
  background-color: var(--brand-gray-light-text);
}

.ProposalStatus_votePageProposalStatus__1OEbX {
  margin-top: 0.8rem;
}

.Proposals_proposals__2mg9W {
  display: flex;
  flex-direction: column;
}

.Proposals_proposals__2mg9W > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.Proposals_heading__26OQ5 {
  font-family: 'Londrina Solid';
  font-size: 40px;
  margin: 0 !important;
}

.Proposals_generateBtn__1iqIa {
  max-width: 10rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green);
  border: var(--brand-color-green);
}
.Proposals_generateBtn__1iqIa:focus,
.Proposals_generateBtn__1iqIa:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
}
.Proposals_generateBtn__1iqIa:active {
  background-color: var(--brand-color-green) !important;
}

.Proposals_generateBtnDisabled__qJ8Gm {
  max-width: 10rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: #f4f4f8;
  border: #f4f4f8;
  color: #8c8d92;
  border: 1px solid #e2e3e8;
}
.Proposals_generateBtnDisabled__qJ8Gm:focus,
.Proposals_generateBtnDisabled__qJ8Gm:hover,
.Proposals_generateBtnDisabled__qJ8Gm:active {
  cursor: not-allowed;
  background-color: #f4f4f8 !important;
  border: #f4f4f8 !important;
  color: #8c8d92 !important;
  border: 1px solid #e2e3e8 !important;
  outline: none;
  box-shadow: none;
}

.Proposals_proposalLink__3idLu {
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e3e8;
  box-sizing: border-box;
  border-radius: 16px;
  background: #f4f4f8;
  font-size: 22px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.Proposals_proposalInfoWrapper__DGaov {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Proposals_proposalLink__3idLu:hover {
  background: white;
  cursor: pointer;
}

.Proposals_markdown__31Gdt h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.Proposals_markdown__31Gdt h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.Proposals_markdown__31Gdt h3 {
  font-size: 1.3rem;
}

.Proposals_markdown__31Gdt img {
  max-width: 100%;
  height: auto;
}

.Proposals_nullStateCopy__3x1zf {
  margin-top: 0.9rem;
  margin-right: 1rem;
  font-family: 'PT Root UI';
  font-weight: 500;
  color: var(--brand-gray-light-text);
  font-size: 16px;
  line-height: 22.4px;
}

.Proposals_mobileCountdownWrapper__32WRj {
  display: none;
}

.Proposals_desktopCountdownWrapper__ebOCx {
  display: flex;
}

.Proposals_nounInWalletBtnWrapper__2Qyfk {
  display: flex;
  padding-top: 0.8rem;
}

@media (max-width: 992px) {
  .Proposals_desktopCountdownWrapper__ebOCx {
    display: none;
  }

  .Proposals_mobileCountdownWrapper__32WRj {
    display: flex;
    width: 100%;
    margin-top: 1rem;
  }

  .Proposals_nullStateCopy__3x1zf {
    margin-top: 0rem;
  }

  .Proposals_proposalTitle__VkUVe {
    max-width: 65%;
    overflow-wrap: break-word;
  }

  .Proposals_nounInWalletBtnWrapper__2Qyfk {
    width: 100%;
  }

  .Proposals_headerWrapper__3uJ1w {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Proposals_forceFlexRow__3WvC2 {
    flex-direction: row;
  }

  .Proposals_heading__26OQ5 {
    width: 100%;
  }

  .Proposals_nullStateSubmitProposalBtnWrapper__lUOAJ {
    text-align: right;
  }
}

.Proposals_nullBtnWrapper__3-6kN {
  min-width: 10rem;
}

.Proposals_proposalId__2GVQA {
  color: #8c8d92;
  margin-right: 0.5rem;
}

.Proposals_proposalTitle__VkUVe {
  width: 80%;
}

.Proposals_proposalStatusWrapper__2axTx {
  margin-left: 0.5rem;
  min-width: -webkit-max-content;
  min-width: max-content;
}

.Proposals_submitProposalButtonWrapper__31ZLW {
  text-align: right;
}

.Proposals_nullStateSubmitProposalBtnWrapper__lUOAJ {
  text-align: right;
}

.Proposals_votePillWrapper__SYS_O {
  max-width: 5rem;
}

.Proposals_countdownPill__2oBM3 {
  background-color: var(--brand-gray-light-text-translucent);
  color: #00000080;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -0.5rem;
}

.Proposals_countdownPillContentWrapper__1TmPe {
  display: flex;
  flex-direction: row;
}

.Proposals_countdownPillClock__2r4fL {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}

.Proposals_countdownPillText__1Zkp4 {
  margin-left: 0.25rem;
}

.Proposals_delegateBtnWrapper__1jw2- {
  padding-left: 0.5rem;
}

.Proposals_changeDelegateBtn__20KXt {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
  max-width: 10rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
}

.Proposals_changeDelegateBtn__20KXt:focus,
.Proposals_changeDelegateBtn__20KXt:hover {
  background-color: #e2e3e8;
  color: black;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.Proposals_changeDelegateBtn__20KXt:active {
  background-color: #e2e3e8 !important;
}

.DelegationModal_backdrop__3GoKW {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(75, 75, 75, 0.5);
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
}

.DelegationModal_content__3WRlu {
  max-height: 50vh;
  overflow-y: hidden;
}

.DelegationModal_modal__nbJ_I {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 15vh;
  z-index: 100;
  background-color: rgba(244, 244, 248, 1);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  border-radius: 24px;
  left: calc(50% - 236px);
  width: 472px;
  max-height: 347px;
}

.DelegationModal_modal__nbJ_I .DelegationModal_header__lrDiO {
  display: flex;
}

.DelegationModal_modal__nbJ_I .DelegationModal_title__3uM-e {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.DelegationModal_modal__nbJ_I .DelegationModal_title__3uM-e h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.DelegationModal_modal__nbJ_I .DelegationModal_title__3uM-e h1 {
  line-height: 42px;
  height: 2rem;
  font-size: 42px;
}

.DelegationModal_closeBtnWrapper__1mXLn {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.DelegationModal_closeBtn__stSL4 {
  z-index: 100;
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.125s ease-in-out;
  border: 0;
}

.DelegationModal_closeBtn__stSL4:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.DelegationModal_closeBtn__stSL4 .DelegationModal_icon__2X23D {
  height: 24px;
  width: 24px;
}

@media (max-width: 992px) {
  .DelegationModal_backdrop__3GoKW {
    background: rgba(0, 0, 0, 0.74);
  }

  .DelegationModal_content__3WRlu {
    max-height: 100%;
    height: 100%;
  }

  .DelegationModal_modal__nbJ_I {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
}

.CurrentDelegatePannel_header__2gUoN {
  padding: 1rem 0.75rem 0rem 0.75rem;
}

.CurrentDelegatePannel_title__91pZe {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
  line-height: 42px;
  height: 2rem;
  font-size: 42px;
  color: var(--brand-cool-dark-text);
}

.CurrentDelegatePannel_copy__16ALG {
  font-weight: 500;
  font-family: 'PT Root UI';
  color: var(--brand-cool-dark-text);
}

.CurrentDelegatePannel_copy__16ALG .CurrentDelegatePannel_emph__3du2_ {
  font-weight: bold;
}

.CurrentDelegatePannel_contentWrapper__1G2cJ {
  border-radius: 14px;
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.CurrentDelegatePannel_current__GCaIr {
  color: var(--brand-cool-light-text);
  font-size: 18px;
  margin-top: 1rem;
  font-weight: 500;
}

.CurrentDelegatePannel_delegateInfoWrapper__3fs7e {
  display: flex;
  flex-direction: column;
}

.CurrentDelegatePannel_ens__3NxOP {
  display: flex;
  flex-direction: row;
  font-size: 26px;
  color: var(--brand-cool-dark-text);
}

.CurrentDelegatePannel_shortAddress__1AQOc {
  color: var(--brand-cool-light-text);
  font-weight: 500;
  font-size: 16px;
  text-align: right;
}

.CurrentDelegatePannel_buttonWrapper__nmJQL {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .CurrentDelegatePannel_wrapper__cZGNs {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .CurrentDelegatePannel_buttonWrapper__nmJQL {
    margin-bottom: 1rem;
  }
}

.DelegationCandidateVoteCountInfo_wrapper__33_OY {
  display: flex;
}

.DelegationCandidateVoteCountInfo_spinner__206ux {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.DelegationCandidateVoteCountInfo_voteInfoWrapper__12v0n {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  color: var(--brand-gray-light-text);
  text-align: right;
}

.DelegationCandidateVoteCountInfo_voteCount__2Wck1 {
  color: black;
  font-weight: bold;
}

.DelegationCandidateInfo_spinner__qInM2 {
  display: flex;
  justify-content: center;
}

.DelegationCandidateInfo_wrapper__1Tkvz {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.DelegationCandidateInfo_delegateCandidateInfoWrapper__1p1rm {
  display: flex;
}

.DelegationCandidateInfo_avatarWrapper__1eNEU {
  margin-right: 1rem;
}

.DelegationCandidateInfo_ensText__wHrDa {
  color: var(--brand-cool-dark-text);
  font-weight: bold;
  font-size: 22px;
}

.DelegationCandidateInfo_shortAddress__2qjqH {
  font-weight: 500;
  font-size: 13px;
  color: var(--brand-cool-light-text);
}

.ChangeDelegatePannel_delegateInput__3ZZcJ {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 54px;
  color: black;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: white;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 25px;
  transition: all 0.2s ease-in-out;
}

.ChangeDelegatePannel_delegateInput__3ZZcJ:focus {
  box-shadow: none !important;
}

.ChangeDelegatePannel_nonEnBottomMargin__2SPRI {
  margin-bottom: 2.1rem;
}

@media (max-width: 992px) {
  .ChangeDelegatePannel_nonEnBottomMargin__2SPRI {
    margin-bottom: 3.5rem;
  }
}

.ChangeDelegatePannel_empty__23o_z {
  border: none;
}

.ChangeDelegatePannel_valid__2ci4L {
  border: 2px solid var(--brand-color-green);
}

.ChangeDelegatePannel_invalid__2wACc {
  border: 2px solid var(--brand-color-red);
}

.ChangeDelegatePannel_buttonWrapper__OA5z3 {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.ChangeDelegatePannel_btnVoteCount__3lF0X {
  align-items: center;
  margin: auto;
}

.ChangeDelegatePannel_delegateKVotesBtn__2H39_ {
  z-index: 101;
}

.ChangeDelegatePannel_delegateKVotesBtn__2H39_ span {
  margin-right: 0.3rem;
}

.ChangeDelegatePannel_highlightCircle__16ni1 {
  width: 22px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  background-image: radial-gradient(#ffffff70 15%, rgba(0, 0, 0, 0) 75%);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 20px;
}

.ChangeDelegatePannel_delegateCandidateInfoWrapper__4lBhM {
  padding: 0rem 0.25rem 0.25rem 0rem;
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .ChangeDelegatePannel_customButtonHighlighter__XizH7 {
    top: 24.15%;
    left: 81.115%;
  }
}

.ChangeDelegatePannel_alreadyDelegatedCopy__3dc6b {
  margin-left: 0.25rem;
  color: var(--brand-cool-light-text);
}

.Governance_heading__MOhD3 {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.Governance_headerRow__ksau1 span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}

.Governance_headerRow__ksau1 h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}

.Governance_subheading__222R0 {
  color: var(--brand-dark-green);
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 1.2rem;
}

.Governance_boldText__NTSL0 {
  font-family: 'PT Root UI';
  font-weight: bold;
}

p {
  padding-top: 1rem;
}

@media (max-width: 992px) {
  .Governance_section__2XsXU {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.Governance_wrapper__2DDiu {
  margin-left: auto;
  margin-right: auto;
}

.Governance_treasuryInfoCard__KCyDP {
  margin-bottom: 3rem;
  border-radius: 20px;
  border: 1px solid #e2e3e8;
}

.Governance_treasuryAmtWrapper__1Mtsd {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.Governance_usdTreasuryAmt__HZORQ {
  padding-top: 0.2rem;
}

.Governance_ethTreasuryAmt__1yXCq {
  display: flex;
  height: 3rem;
  min-width: 9rem;
  padding-top: 0.2rem;
}

.Governance_ethTreasuryAmt__1yXCq h1 {
  font-size: 36px;
  font-family: 'Londrina Solid';
}

.Governance_usdBalance__1diQE {
  font-size: 36px;
  font-family: 'Londrina Solid';
}

.Governance_ethSymbol__2McN7 {
  font-family: 'PT Root UI' !important;
  margin-right: 0.5rem;
}

.Governance_usdBalance__1diQE {
  color: var(--brand-gray-light-text);
  font-family: 'Londrina Solid';
}

.Governance_treasuryInfoText__xxow6 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: 'PT Root UI';
  font-weight: 500;
}

@media (min-width: 992px) {
  .Governance_treasuryAmtWrapper__1Mtsd {
    border-right: 2px solid #e2e3e8;
  }

  .Governance_ethTreasuryAmt__1yXCq {
    border-right: 2px solid #e2e3e8;
  }
}

.CreateProposal_createProposalPage__3qx3H a {
  color: var(--brand-dark-red);
}

.CreateProposal_createProposalForm__1UHgT {
  border-radius: 5px;
  padding: 1rem 2.5rem;
  margin-top: 1em;
  background-color: white;
}

.CreateProposal_heading__e7kWw {
  margin: 1rem 0;
}

.CreateProposal_section__2A56Y {
  border-top: 1px solid #e9ecef;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.CreateProposal_addTransactionButton__3U7s3,
.CreateProposal_createProposalButton__UHWVW {
  margin-top: 1rem;
}

.ProposalEditor_proposalEditor__19xXK {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_titleInput__2oS3A,
.ProposalEditor_bodyInput__20KWn {
  padding: 0;
  border: none;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_titleInput__2oS3A {
  font-size: 1.25rem;
}

.ProposalEditor_bodyInput__20KWn {
  min-height: 340px !important;
}

.ProposalEditor_divider__38QOx {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.ProposalEditor_previewArea__1FpbY {
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_markdown__2ReG4 h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalEditor_markdown__2ReG4 h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalEditor_markdown__2ReG4 h3 {
  font-size: 1.3rem;
}

.ProposalEditor_markdown__2ReG4 img {
  max-width: 100%;
  height: auto;
}

.ProposalTransactions_popover__3fRG9 {
  max-width: 600px;
}

.ProposalTransactions_transactionDetails__2EchO {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
}

.ProposalTransactions_removeTransactionButton__2dBl_ {
  border: none;
  background: none;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalTransactions_removeTransactionButton__2dBl_ img {
  width: 1rem;
}

.ProposalTransactionFormModal_transactionFormModal__2GUdX {
  max-width: 600px;
}

.ProposalTransactionFormModal_stepProgressBar__4Na9W {
  padding: 0 0 2rem 0 !important;
}

.ProposalTransactionFormModal_inputGroupText__3ruwH {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
}

.ProposalTransactionFormModal_functionName__2XKIf {
  white-space: nowrap;
}

.Vote_votePage__G1H10 a {
  color: var(--brand-dark-red);
}

.Vote_proposal__1pjGZ {
  margin-top: 1em;
  background-color: white;
}

.Vote_backArrow__3z9lF {
  height: 1rem;
}

.Vote_votingButton__3PrIp {
  margin-top: 1rem;
}

.Vote_voteCountCard__11har {
  margin-top: 1rem;
}

.Vote_proposalId__1ti1o {
  margin: 1rem 0;
}

.Vote_voteCountCard__11har p {
  display: flex;
  justify-content: space-between;
}

.Vote_section__3S8l3 {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.Vote_section__3S8l3 h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.Vote_voterIneligibleAlert__2jDfQ {
  margin: 1rem 0 0 0;
}

.Vote_blockRestrictionAlert__1X0x6 {
  margin: 1rem 0 0 0;
}

.Vote_wrapper__3XVzk {
  margin-left: auto;
  margin-right: auto;
}

.Vote_transitionStateButton__3qBjX {
  height: 50px;
  border-radius: 8px;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 24px;
  transition: all 0.125s ease-in-out;
}

.Vote_transitionStateButton__3qBjX:hover {
  opacity: 0.5;
  cursor: pointer;
}

.Vote_transitionStateButtonSection__1iavo {
  border-top: 0px;
}

.Vote_spinner__2e2Ue {
  margin-left: auto;
  margin-right: auto;
  color: var(--brand-gray-light-text);
}

/* Info section stuff */
.Vote_voteInfoCard__1X8sV {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 12px;
}

.Vote_voteMetadataRow__2OLhz {
  display: flex;
  justify-content: space-between;
}

.Vote_voteMetadataRow__2OLhz h1 {
  font-size: 20px;
  color: var(--brand-gray-light-text);
  font-family: 'Londrina Solid';
}

.Vote_voteMetadataRow__2OLhz span {
  font-size: 14px;
  font-family: 'PT Root UI';
  color: var(--brand-gray-light-text);
}

.Vote_voteMetadataRow__2OLhz h3 {
  font-size: 18px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.Vote_voteMetadataRowTitle__yUKZ0 {
  margin-top: 0.5rem;
  width: -webkit-max-content;
  width: max-content;
}

.Vote_voteMetadataTime__3unTi {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: right;
}

.Vote_snapshotBlock__VMn7W {
  text-align: right;
}

.Vote_thresholdInfo__8xlb3 {
  text-align: right;
}

.Vote_toggleDelegateVoteView__8Pni3 {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  opacity: 0.5;
  font-size: 14px;
  cursor: pointer;
  transition: ease-in-out 125ms;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 0.1rem;
}

.Vote_toggleDelegateVoteView__8Pni3:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .Vote_toggleDelegateVoteView__8Pni3 {
    display: none;
  }
}

.VoteModal_voteModal__19gv1 {
  font-family: 'PT Root UI';
}

.VoteModal_propTitle__3x-Yf {
  font-family: 'Londrina Solid';
  font-size: 32px;
  margin-bottom: 5px;
}

.VoteModal_voteIcon__3Bqua {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.VoteModal_submitBtn__3DsnJ {
  width: 100%;
  min-width: 8rem;
  height: 40px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green) !important;
  border: var(--brand-color-green) !important;
  color: white !important;
}
.VoteModal_submitBtn__3DsnJ:focus,
.VoteModal_submitBtn__3DsnJ:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
  border: var(--brand-color-green) !important;
}
.VoteModal_submitBtn__3DsnJ:active {
  background-color: var(--brand-color-green) !important;
}

.VoteModal_submitBtnDisabled__2rMJV {
  background-color: var(--brand-gray-light-text) !important;
  width: 100%;
  min-width: 8rem;
  height: 40px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  border: var(--brand-gray-light-text);
  opacity: 0.5;
  box-shadow: none !important;
}

.VoteModal_submitBtnDisabled__2rMJV:hover,
.VoteModal_submitBtnDisabled__2rMJV:active,
.VoteModal_submitBtnDisabled__2rMJV:focus {
  cursor: not-allowed;
  box-shadow: none !important;
  color: white !important;
}

.VoteModal_header__11wDB {
  border-bottom: 0px;
}

.VoteModal_votingButtonsWrapper__2ZIR7 {
  transition: all 0.125s ease-in-out;
}

.VoteModal_loadingSpinner__3d36m {
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
  color: var(--brand-gray-light-text);
}

.VoteModal_transactionStatus__XIqRz {
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.VoteModal_disabled__2l6K5 {
  opacity: 0.5;
}

.VoteModal_voteSuccessBody__2Ilpn {
  margin-top: 1rem;
}

.VoteModal_voteFailureTitle__2i0IW {
  font-weight: normal;
  width: 100%;
}

.VoteModal_voteFailureBody__2ICmq {
  margin-top: 1rem;
  font-weight: bold;
}

.VoteModal_voteFailureErrorMessage__P-Il4 {
  font-weight: bold;
  color: var(--brand-color-red);
}

.VoteModal_voteReasonTextarea__23cgn {
  padding-top: 2rem !important;
  height: 6.5rem !important;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

.VoteModal_voteReasonTextarea__23cgn:focus {
  box-shadow: none;
}

.ProposalHeader_backButton__3n9fs {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 1rem;
  margin-top: 0.1rem;
}

.ProposalHeader_headerRow__2s3d4 span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.ProposalHeader_headerRow__2s3d4 h1 {
  color: #14161b;
  font-size: 42px;
  font-family: 'Londrina Solid';
}

.ProposalHeader_proposalStatus__QaV8n {
  margin-left: 0.75rem;
  margin-top: 0.1rem;
}

.ProposalHeader_connectWalletText__1GGnk {
  color: var(--brand-gray-light-text);
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 16px;
  min-width: 12rem;
  margin-bottom: 1.15rem;
}

.ProposalHeader_noVotesText__2GCQ9 {
  color: var(--brand-gray-light-text);
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1.15rem;
  min-width: 9.5rem;
}

.ProposalHeader_submitBtn__1dlEY {
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green);
  border: var(--brand-color-green);
}
.ProposalHeader_submitBtn__1dlEY:focus,
.ProposalHeader_submitBtn__1dlEY:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
}
.ProposalHeader_submitBtn__1dlEY:active {
  background-color: var(--brand-color-green) !important;
}
.ProposalHeader_submitBtnDisabled__1xAz_ {
  background-color: var(--brand-gray-light-text) !important;
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  border: var(--brand-dark-red);
  opacity: 0.5;
  box-shadow: none !important;
}

.ProposalHeader_submitBtnDisabled__1xAz_:hover,
.ProposalHeader_submitBtnDisabled__1xAz_:active,
.ProposalHeader_submitBtn__1dlEY:focus {
  cursor: not-allowed;
  box-shadow: none !important;
}

.ProposalHeader_proposalTitleWrapper__3w5EB {
  display: flex;
  padding-right: 2rem;
}

.ProposalHeader_proposalTitle__20ZSL {
  margin-right: 0.5rem;
}

.ProposalHeader_mobileSubmitProposalButton__hmFGT {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 992px) {
  .ProposalHeader_submitBtn__1dlEY {
    max-width: 100%;
  }

  .ProposalHeader_submitBtnDisabled__1xAz_ {
    max-width: 100%;
  }
}

.ProposalHeader_voterIneligibleAlert__31ibs {
  font-family: 'PT Root UI';
  font-weight: 500;
  background-color: #e2e3e8;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.ProposalHeader_byLineWrapper__3eq3P {
  display: flex;
  flex-direction: row;
}

.ProposalHeader_byLineWrapper__3eq3P h3 {
  font-family: 'Londrina Solid';
  color: var(--brand-gray-light-text);
  margin-left: 3rem;
  font-size: 24px;
}

.ProposalHeader_byLineContentWrapper__1N1uw {
  display: flex;
  flex-direction: row;
  margin-left: 0;
}

.ProposalHeader_propTransactionWrapper__2lM2O {
  margin-left: 0.33rem;
  margin-right: 0.5rem;
  font-size: 18px;
  color: var(--brand-gray-light-text);
}
.ProposalHeader_propTransactionWrapperJp__2btkr {
  margin-left: 0.37rem;
  margin-right: 0.25rem;
  color: var(--brand-gray-light-text);
}

.ProposalHeader_proposedByJp__-DZiP {
  color: var(--brand-gray-light-text);
}

.ProposalHeader_proposerLinkJp__3zfxy {
  margin-right: 0.25rem;
}

.ProposalHeader_proposalByLineWrapperJp__2qCJT {
  font-weight: 500;
  margin-left: 2.5rem;
}

.ProposalHeader_propTransactionHash__1qOc8 {
  margin-left: 0.2rem;
}

.ProposalHeader_byLineContentWrapper__1N1uw h3 {
  margin-left: 0.5rem;
}

@media (max-width: 450px) {
  .ProposalHeader_byLineWrapper__3eq3P {
    flex-direction: column;
  }

  .ProposalHeader_byLineContentWrapper__1N1uw {
    margin-left: 2.5rem;
  }

  .ProposalHeader_proposalByLineWrapperJp__2qCJT {
    flex-direction: column;
    display: flex;
  }

  .ProposalHeader_proposerJp__3UNTk {
    margin-left: 0.5rem;
  }

  .ProposalHeader_proposedByJp__-DZiP {
    margin-left: 0.5rem;
  }
}

.ProposalContent_section__hGx6B {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.ProposalContent_section__hGx6B h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.ProposalContent_markdown__3dCs8 {
  font-family: 'PT Root UI';
  font-size: 1.1rem;
}

.ProposalContent_markdown__3dCs8 h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalContent_markdown__3dCs8 h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalContent_markdown__3dCs8 h3 {
  font-weight: bold;
  font-size: 1.3rem;
}

.ProposalContent_markdown__3dCs8 img {
  max-width: 100%;
  height: auto;
}

.HoverCard_hover__2x2iv {
  box-sizing: border-box;
  min-width: 217px;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  left: 301px;
  top: 396px;

  /* White */
  background-color: #ffffff !important;

  /* Gray/Border */
  border: 1px solid #e2e3e8 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.01) !important;
  border-radius: 14px !important;
  color: black !important;
}

.HorizontalStackedNouns_wrapper__2QZh9 {
  position: relative !important;
  margin-bottom: 50px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.HorizontalStackedNouns_nounWrapper__1HsPJ {
  position: absolute;
}

.ByLineHoverCard_wrapper__2d-ze {
  display: flex;
  flex-direction: column;
}

.ByLineHoverCard_stackedNounWrapper__11GB1 {
  display: flex;
}

.ByLineHoverCard_address__1VV3Q {
  font-family: 'Londrina Solid';
  font-size: 24px;
  width: 100%;
  text-align: left;
}

.ByLineHoverCard_nounsRepresented__39R09 {
  color: var(--brand-gray-dark-text);
  display: flex;
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 15px;
  max-width: 11rem;
}

.ByLineHoverCard_bold__yLcyI {
  font-weight: bold;
}

.ByLineHoverCard_spinnerWrapper__3lw8h {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ByLineHoverCard_spinner__2demJ {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ByLineHoverCard_icon__1SVbH {
  margin-bottom: 5px;
  margin-right: 6px;
}

.NounImageVoteTable_wrapper__TMwqB {
  margin-left: 0.25rem;
}

.GrayCircle_wrapper__3H09A {
  height: 55px;
  width: 55px;
}

.NounHoverCard_wrapper__3RT3_ {
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.NounHoverCard_spinnerWrapper__GUxDk {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.NounHoverCard_spinner__29rKW {
  display: flex;
  width: 100%;
  justify-content: center;
}

.NounHoverCard_titleWrapper__3cx2l {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.NounHoverCard_titleWrapper__3cx2l h1 {
  color: black;
  font-family: 'Londrina Solid';
  font-size: 24px;
  margin-top: 0.45rem;
  margin-left: 0.5rem;
}

.NounHoverCard_nounWrapper__38kwt {
  height: 42px;
  width: 42px;
}

.NounHoverCard_nounInfoWrapper__2ZN1d {
  margin-top: 0.75rem;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;
          font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;

  color: var(--brand-gray-dark-text);
}

.NounHoverCard_bold__3SWJp {
  font-weight: bold;
  margin-left: 0.25rem;
}

.NounHoverCard_icon__3NSBq {
  margin-right: 7px;
}

.NounHoverCard_cta__2RpFj {
  color: var(--brand-gray-light-text);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;
          font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;
}

.NounHoverCard_currentHolder__2YO2E {
  margin-bottom: 1rem;
}

.VoteCardPager_pageDots__2qKMa {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: var(--brand-gray-light-text);
}

.VoteCardPager_disabledPageDot__2Fj8y {
  opacity: 0.5;
}

.VoteCardPager_paginationArrowsWrapper__16MKK {
  display: flex;
  justify-content: center;
}

.VoteCardPager_paginationArrowBtnWrapper__3cNKt {
  border: none;
  background-color: transparent;
}

.VoteCardPager_paginationArrowBtnWrapper__3cNKt:disabled {
  opacity: 0.5;
}

.VoteCardPager_paginationArrow__3IBFg {
  height: 28px;
  width: 28px;
  color: var(--brand-gray-light-text);
}

.VoteCardPager_disabled__7Pbuv {
  opacity: 0.25;
}

.VoteProgressBar_wrapper__a8wkH {
  border-radius: 6px;
  height: 1rem;
}

.VoteProgressBar_progressBar__35-Ke {
  height: 100%;
  border-radius: 6px;
}

.VoteProgressBar_forWrapper__RjvRz {
  background-color: var(--brand-color-green-translucent);
}

.VoteProgressBar_forProgressBar__3JlTZ {
  background-color: var(--brand-color-green);
}

.VoteProgressBar_againstWrapper__IukLN {
  background-color: var(--brand-color-red-translucent);
}

.VoteProgressBar_againstProgressBar__15RmJ {
  background-color: var(--brand-color-red);
}

.VoteProgressBar_abstainWrapper__13Z8S {
  background-color: var(--brand-gray-light-text-translucent);
}

.VoteProgressBar_abstainProgressBar__HzhI2 {
  background-color: var(--brand-gray-light-text);
}

.VoteCard_voteCardHeaderTextEn__1ABnI {
  font-size: 22px;
  font-family: 'Londrina Solid';
}

.VoteCard_voteCardHeaderTextNonEn__ft_XX {
  font-size: 16px;
  font-family: 'Londrina Solid';
}

.VoteCard_voteCardVoteCount__1ij4- {
  font-size: 22px;
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-top: 0.15rem;
}

.VoteCard_for__2Csem {
  color: var(--brand-color-green);
}

.VoteCard_against__3Xhru {
  color: var(--brand-color-red);
}

.VoteCard_abstain__1szzM {
  color: var(--brand-gray-light-text);
}

.VoteCard_voteCountCard__2YaCa {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 12px;
  background-color: #f4f4f8;
  min-height: 18rem;
}

.VoteCard_voteCountCard__2YaCa p {
  display: flex;
  justify-content: space-between;
}

.VoteCard_nounProfilePics__27dmE {
  margin-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.VoteCard_mobileVoteCountWrapper__191hv {
  display: none !important;
}

.VoteCard_unitTextEn__2mAmE {
  opacity: 0.5;
  font-size: 18px;
  font-weight: 500;
}

.VoteCard_unitTextNonEn__3pL9x {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
}

.VoteCard_smallerVoteCountText__3iQJD {
  font-size: 16px;
}

@media (max-width: 1200px) {
  .VoteCard_voteCountCard__2YaCa {
    min-height: 0rem;
  }

  .VoteCard_unitTextEn__2mAmE {
    font-size: 16px;
  }

  .VoteCard_unitTextNonEn__3pL9x {
    font-size: 13px;
  }

  .VoteCard_mobileVoteCountWrapper__191hv {
    display: flex !important;
    flex-direction: column;
  }

  .VoteCard_voteCardVoteCount__1ij4- {
    margin-left: auto;
    margin-right: auto;
  }

  .VoteCard_wrapper__1RqUm {
    width: 33%;
  }

  .VoteCard_voteCardHeaderTextEn__1ABnI {
    margin-left: auto;
    margin-right: auto;
  }

  .VoteCard_voteCardHeaderTextNonEn__ft_XX {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .VoteCard_nounProfilePics__27dmE {
    display: none;
  }
}

.DelegateHoverCard_wrapper__2R0D9 {
  display: flex;
  flex-direction: column;
  max-width: 11rem;
}

.DelegateHoverCard_stackedNounWrapper__11AJJ {
  display: flex;
}

.DelegateHoverCard_address__L2D-i {
  font-family: 'Londrina Solid';
  font-size: 24px;
  width: 100%;
  text-align: left;
}

.DelegateHoverCard_nounsRepresented__-JNhe {
  color: var(--brand-gray-dark-text);
  display: flex;
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 15px;
}

.DelegateHoverCard_bold__2T6j0 {
  font-weight: bold;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.DelegateHoverCard_spinnerWrapper__1wfGt {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DelegateHoverCard_spinner__1Pvd8 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.DelegateHoverCard_nounInfoWrapper__3p3q2 {
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  -webkit-font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;
          font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;

  color: var(--brand-gray-dark-text);
}

.DelegateHoverCard_icon__31v09 {
  margin-bottom: 5px;
  margin-right: 6px;
}

.DelegateGroupedNounImageVoteTable_wrapper__GLPLV {
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.DelegateGroupedNounImageVoteTable_nounCell__QPC3S {
  width: 55px;
  text-align: center;
}

.NoundersPage_noundersPage__2BU9Y h1,
.NoundersPage_noundersPage__2BU9Y h2,
.NoundersPage_noundersPage__2BU9Y h3 {
  font-family: 'Londrina Solid';
}

.NoundersPage_noundersPage__2BU9Y h2 {
  margin-bottom: 2rem;
}

.NoundersPage_noundersPage__2BU9Y a {
  color: var(--brand-black);
}
.NoundersPage_noundersPage__2BU9Y a:hover {
  color: var(--brand-dark-red);
}

.NoundersPage_bioGroup__26YGW {
  padding-right: 5px;
  padding-left: 5px;
}
.NoundersPage_bioGroup__26YGW a {
  font-size: 1rem;
}

.NoundersPage_noundersPage__2BU9Y img {
  border-radius: 50%;
  width: 50%;
}

.NoundersPage_marginBottom__dDAL1 {
  margin-bottom: 0rem;
}

.NoundersPage_bioGroup__26YGW {
  margin-bottom: 2rem;
  text-align: center;
}
.NoundersPage_bioGroup__26YGW a:hover svg {
  fill: var(--brand-dark-red);
}
.NoundersPage_bioGroup__26YGW img {
  margin-bottom: 1rem;
}

.NoundersPage_twitterIcon__3L0yj {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.NoundersPage_card__1wIOD {
  border: none;
}

.NoundersPage_card__1wIOD,
.NoundersPage_headerWrapper__1PZ9J {
  font-size: 1.3rem;
}
.NoundersPage_cardHeader__1GQQt {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.NotFound_heading__3xG74 {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.Playground_headerRow__1PbPl {
  margin: 2rem 0;
}
.Playground_headerRow__1PbPl span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.Playground_headerRow__1PbPl h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}
.Playground_headerRow__1PbPl p {
  color: #14161b;
}

.Playground_primaryBtn__3BNgS {
  width: 100%;
  height: 4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.Playground_primaryBtn__3BNgS:focus,
.Playground_primaryBtn__3BNgS:hover {
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}
.Playground_primaryBtn__3BNgS:active {
  background-color: var(--brand-dark-red) !important;
}
.Playground_traitForm__2IWAH {
  height: 4rem;
}
.Playground_traitFormBtn__iYP0D {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.Playground_traitFormBtn__iYP0D:hover,
.Playground_traitFormBtn__iYP0D:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.Playground_floatingLabel__2VKJB,
.Playground_fileUpload__udPZ9 {
  font-size: 15px;
  color: #8c8d92;
}
.Playground_nounYearsFooter__3eTZ2 {
  font-style: italic;
}
.Playground_nounImg__3cni_ {
  border-radius: 16px;
}
.Playground_nounImg__3cni_:hover {
  cursor: pointer;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
.Playground_nounWrapper__1TyU3 {
  margin-bottom: 1rem;
}

.NounModal_modal__24Xjq {
  position: fixed;
  top: 15vh;
  z-index: 100;
  padding: 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 12.5rem);
  width: 25rem;
}

.NounModal_displayNounFooter__9N9FF {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NounModal_displayNounFooter__9N9FF span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.NounModal_displayNounFooter__9N9FF button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.NounModal_displayNounFooter__9N9FF button:hover,
.NounModal_displayNounFooter__9N9FF button:active,
.NounModal_displayNounFooter__9N9FF button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.NounModal_nounImg__1_lzZ {
  border-radius: 16px;
}
.NounModal_nounWrapper__14FkD {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .NounModal_modal__24Xjq {
    width: 80% !important;
    left: 10% !important;
  }
}

