body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* Load custom fonts */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

@font-face {
  font-family: 'Londrina Solid';
  src: url(./fonts/Londrina_Solid/LondrinaSolid-Black.ttf);
  src: url(./fonts/Londrina_Solid/LondrinaSolid-Regular.ttf);
}
@font-face {
  font-family: 'PT Root UI';
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Regular.woff2) format('woff2'),
    url(./fonts/PT_Root_UI/PT-Root-UI_Regular.woff) format('woff');
}
@font-face {
  font-family: 'PT Root UI';
  font-weight: 500;
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Medium.woff2) format('woff2'),
    url(./fonts/PT_Root_UI/PT-Root-UI_Medium.woff) format('woff');
}

@font-face {
  font-family: 'PT Root UI';
  font-weight: bold;
  src: url(./fonts/PT_Root_UI/PT-Root-UI_Bold.woff2) format('woff2'),
    url(./fonts/PT_Root_UI/PT-Root-UI_Bold.woff) format('woff');
}
